import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import logger from "../helpers/logger";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { AcceptedShippingCountries, AddressLookupData, AddressLookupValue, HabitualAddress } from "../types/types"
import { AddressLookup } from "./address-lookup"

export const ChangePatientAddress = ({
  data,
  refresh,
}: {
  data: {
    email: string;
    hubspotId: string;
    shippingAddress: HabitualAddress
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { email, shippingAddress } = data || {};

  const [addressDetails, setAddressDetails] = useState<AddressLookupData>({
    value: {
      addressLine1: {
        value: shippingAddress?.address1 || "",
        isValid: !!shippingAddress?.address1,
      },
      addressLine2: {
        value: shippingAddress?.address2|| "",
        isValid: true,
      },
      city: {
        value: shippingAddress?.city|| "",
        isValid: !!shippingAddress?.city,
      },
      county: {
        value: shippingAddress?.county|| "",
        isValid: true,
      },
      country: {
        value: shippingAddress?.country|| "",
        isValid: !!shippingAddress?.country,
      },
      postcode: {
        value: shippingAddress?.postcode|| "",
        isValid: !! shippingAddress?.postcode,
      },
    },
    isValid: false,
  });
  const [addressLookupList, setAddressLookupList] = useState([]);
  const [tempKey, setTempKey] = useState(0);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleAddressChange = (addressDetails: AddressLookupData, updatePendingOrders?: boolean) => {
    console.log('Saving address', addressDetails, updatePendingOrders)
    const newShippingAddress: HabitualAddress = {
      address1: addressDetails.value.addressLine1.value,
      address2: addressDetails.value.addressLine2.value,
      city: addressDetails.value.city.value,
      county: addressDetails.value.county.value,
      country: addressDetails.value.country.value as AcceptedShippingCountries,
      postcode: addressDetails.value.postcode.value.toUpperCase()
    }
    const requests = [
      () =>
        axios({
          method: "post",
          url: `${apiEndpoint}admin/patient/${email}/shippingAddress`,
          data: {
            shippingAddress: newShippingAddress,
            isDefault: true,
            updatePendingOrders,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Patient default shipping address changed:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Patient default shipping address was changed from ${shippingAddress?.address1} ${shippingAddress?.postcode?.toUpperCase()} to ${addressDetails.value.addressLine1.value}, ${addressDetails.value.postcode.value.toUpperCase()}`,
          });
        }
        return response1.status;
      })
      .then((status: number) => {
        console.log('status code is', status)
        if (status === 202) {
          alert('Automatic update was not possible. A message has been posted to slack to try to intercept the package, please go and tag whoever is on TIP support today')
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return (
    <Fragment>
      <div>
        <label>{`Change patient default shipping address in the database to: `}</label>
        <AddressLookup
            addressDetails={addressDetails}
            setAddressDetails={(details: any) => {
              setAddressDetails(details);
              return;
            }}
            addressLookupList={addressLookupList}
            setAddressLookupList={(addresses: any) => {
              setAddressLookupList(addresses);
            }}
            addressKey={tempKey}
            setAddressKey={() => {
              setTempKey(tempKey + 1);
            }}
            failureCallback={() => {
              return;
            }}
            showUpdatePendingOrders={true}
            saveNewAddress={handleAddressChange}
          />
      </div>
    </Fragment>
  );
};
