import axios from "axios";
import {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import "./globals.css";
import { PatientData } from "./patient-data";
import { PatientNotes } from "./patient-notes";
import { MagicLinkSender } from "./magic-link-sender";
import { ChangeStatus } from "./change-status";
import { ChangeEconsultStatus } from "./change-econsult-status";
import { ChangeWeekNumber } from "./change-week-number";
import { PatientTdrSubscription } from "./patient-tdr-subscription";
import { UpdateStartWeight } from "./update-start-weight";
import { ChangeStartDate } from "./change-start-date";
import { PatientList } from "./patient-list";
import { ChangeProgramme } from "./change-programmecmsid";
import { ChangeProduct } from "./change-product";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { PatientMealOrders } from "./patient-meal-orders";
import {
  CreatePatientMealOrder,
  MealProductData,
} from "./create-patient-meal-order";
import {
  EditFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from "./patient-feature-flags";
import { EditPatientCoins } from "./change-patient-coins";
import { ChangePaymentDate } from "./change-payment-date";
import { ResetToWelcomeGuide } from "./reset-to-welcome-guide";
import { ProgrammeType } from "../helpers/programmeHelper";
import { ChecklistTasks } from "./id-verification";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { MedicalReviewResponses } from "./medical-review-responses";
import { InitialConsultationResponses } from "./initial-consulation-responses";
import { ChangePatientAddress } from "./change-patient-address"

export const isStaging = process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "pre-production";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
export const apiPaymentsEndpoint = process.env.REACT_APP_PAYMENT_URL;
export const glpApiEndpoint = process.env.REACT_APP_GLP_URL;

enum PAGE_TYPES {
  VIEW_PATIENT_DATA = "VIEW_PATIENT_DATA",
  CHANGE_STATUS = "CHANGE_STATUS",
  // CHANGE_ECONSULT_STATUS = "CHANGE_ECONSULT_STATUS",
  CHANGE_WEEK_NUMBER = "CHANGE_WEEK_NUMBER",
  UPDATE_START_WEIGHT = "UPDATE_START_WEIGHT",
  SELECT_START_DATE = "SELECT_START_DATE",
  SWITCH_PROGRAMME = "SWITCH_PROGRAMME",
  SWITCH_GLP_PROGRAMME = "SWITCH_GLP_PROGRAMME",
  SEND_MAGIC_LINK = "SEND_MAGIC_LINK",
  VIEW_PATIENT_NOTES = "VIEW_PATIENT_NOTES",
  FEATURE_FLAGS = "FEATURE_FLAGS",
  COINS = "COINS",
  CHANGE_PAYMENT_DATE = "CHANGE_PAYMENT_DATE",
  CHANGE_SHIPPING_ADDRESS = "CHANGE_SHIPPING_ADDRESS",
  RESET_TO_WELCOME_GUIDE = "RESET_TO_WELCOME_GUIDE",
  ID_VERIFICATION = "ID_VERIFICATION",
  MEDICAL_REVIEW = "MEDICAL_REVIEW",
  INITIAL_CONSULTATION = "INITIAL_CONSULTATION",
}

interface Props {
  setLoading: (isLoading: boolean) => void;
  initialEmail?: string;
  isHabitualAdmin?: boolean;
}

interface PatientsByProps {
  patientsByEmail: any[];
  patientsById: any[];
  patientsByName: any[];
}

export const ManagePatients = ({
  setLoading,
  initialEmail,
  isHabitualAdmin,
}: Props) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  const [error, setError] = useState<boolean>(false);
  const [patientQuery, setPatientQuery] = useState("");
  const [patientList, setPatientList] = useState<PatientsByProps | null>(null);
  const [email, setEmail] = useState<string | null>(initialEmail || null);
  const [patient, setPatient] = useState<any>(null);
  const [patientNotes, setPatientNotes] = useState<string[] | undefined>();
  const [patientMealOrders, setPatientMealOrders] = useState<
    any[] | undefined
  >();
  const [patientMealProducts, setPatientMealProducts] = useState<
    MealProductData | undefined
  >();
  const [flags, setFlags] = useState<FeatureFlag[] | null>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [activity, setActivity] = useState<string>(
    PAGE_TYPES.VIEW_PATIENT_DATA
  );
  const navigate = useNavigate();

  const handlePatientNotes = useCallback(
    async (email) => {
      setLoading(true);
      if (!authToken) {
        console.log("No user auth token");
      } else {
        await axios({
          method: "get",
          url: `${apiEndpoint}admin/patient/${email}/notes`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
        }).then((response) => {
          if (response.data.PatientNotes) {
            const sortedNotes = response.data.PatientNotes.sort(
              (a: { createdAt: Date }, b: { createdAt: Date }) =>
                a.createdAt > b.createdAt ? -1 : 1
            );
            setPatientNotes(sortedNotes);
          } else {
            setPatientNotes([]);
          }
          setLoading(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken]
  );

  // const handlePatientMealOrders = useCallback(
  //   async (email) => {
  //     setLoading(true);
  //     await axios({
  //       method: "get",
  //       url: `${apiEndpoint}admin/patient/${email}/mealOrders`,
  //       headers: {
  //         "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     }).then((response) => {
  //       if (response.data) {
  //         setPatientMealOrders(response.data);
  //       } else {
  //         setPatientMealOrders([]);
  //       }
  //       setLoading(false);
  //     });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [authToken]
  // );

  // const handlePatientMealProducts = useCallback(
  //   async (email) => {
  //     setLoading(true);
  //     await axios({
  //       method: "get",
  //       url: `${apiEndpoint}admin/patient/${email}/mealProducts`,
  //       headers: {
  //         "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     }).then((response) => {
  //       if (response.data) {
  //         setPatientMealProducts(response.data);
  //       } else {
  //         setPatientMealProducts(undefined);
  //       }
  //       setLoading(false);
  //     });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [authToken]
  // );

  const handlePatientFeatureFlags = useCallback(
    async (email) => {
      await axios({
        method: "get",
        url: `${apiEndpoint}admin/patient/${email}/featureFlagsV2`,
        headers: {
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response: { data: FeatureFlag[] }) => {
          if (response?.data) {
            setFlags(response.data);
          }
        })
        .catch((error: any) => {
          console.log(error.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken]
  );

  const handlePatientsSearch = useCallback(
    () => {
      if (!patientQuery || !authToken) return;
      setLoading(true);
      setError(false);
      const url = isHabitualAdmin
        ? `${apiEndpoint}admin/patients/${patientQuery}`
        : `${apiEndpoint}momenta/patients/${patientQuery}`;
      axios({
        method: "get",
        url,
        headers: {
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(async (response) => {
          return { ...response.data };
        })
        .then((data) => {
          setPatient(null);
          setPatientList(data);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patientQuery, authToken]
  );

  const handlePatientDetailsSearch = useCallback(
    () => {
      if (!email || !authToken) return;
      setLoading(true);
      setError(false);
      const requests = [
        () =>
          axios({
            method: "get",
            url: `${apiEndpoint}admin/patient/${email}`,
            headers: {
              "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
              Authorization: `Bearer ${authToken}`,
            },
          }),
        () =>
          axios({
            method: "get",
            url: `${apiEndpoint}admin/patient/${email}/tasks`,
            headers: {
              "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
              Authorization: `Bearer ${authToken}`,
            },
          }),
      ];
      promiseAccumulator(requests)
        .then(async ([response1, response4]: any) => {
          if (response1.data) {
            await handlePatientNotes(email);
            // await handlePatientMealOrders(email);
            // await handlePatientMealProducts(email);
            await handlePatientFeatureFlags(email);
          }
          return {
            ...response1.data,
            tasks: response4?.data,
          };
        })
        .then((data) => {
          setPatient(data);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, handlePatientNotes, refreshCounter, authToken]
  );

  useEffect(
    () => {
      email && authToken && handlePatientDetailsSearch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, authToken]
  );

  useEffect(
    () => {
      if (patient && !patient.email) {
        alert(
          "email has gone to null, need to search for this patient again - sorry! Please let dev know what you were doing immediately before this happened"
        );
        setPatient(null);
        setActivity(PAGE_TYPES.VIEW_PATIENT_DATA);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patient]
  );

  useEffect(
    () => {
      refreshCounter !== 0 && handlePatientDetailsSearch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshCounter]
  );

  const activityMapper = useMemo(
    () => {
      return {
        [PAGE_TYPES.VIEW_PATIENT_DATA]: {
          label: "View patient details",
          content: <PatientData data={patient} />,
        },
        [PAGE_TYPES.CHANGE_STATUS]: {
          label: "Change patient status",
          content: (
            <ChangeStatus
              data={patient}
              refresh={() => {
                setRefreshCounter(refreshCounter + 1);
              }}
            />
          ),
        },
        // [PAGE_TYPES.CHANGE_ECONSULT_STATUS]: {
        //   label: "Change patient econsult status",
        //   content:
        //     isHabitualAdmin &&
        //     patient &&
        //     patient.patientType !== ProgrammeType.GLP ? (
        //       <ChangeEconsultStatus
        //         data={patient}
        //         refresh={() => {
        //           setRefreshCounter(refreshCounter + 1);
        //         }}
        //       />
        //     ) : null,
        // },
        [PAGE_TYPES.CHANGE_WEEK_NUMBER]: {
          label: "Change programme week number",
          content: patient?.["status"] === "ACTIVE" &&
            patient?.["programmeStage"]["startDate"] &&
            patient?.["programmeStage"]["week"] !== undefined &&
            !isNaN(patient?.["programmeStage"]["week"]) && (
              <ChangeWeekNumber
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
            ),
        },
        [PAGE_TYPES.UPDATE_START_WEIGHT]: {
          label: "Change starting weight",
          content: patient?.["startWeight"] && (
            <UpdateStartWeight
              data={patient}
              refresh={() => {
                setRefreshCounter(refreshCounter + 1);
              }}
            />
          ),
        },
        [PAGE_TYPES.SELECT_START_DATE]: {
          label: "Select start date",
          content: patient?.["status"] === "FREE_TRIAL" &&
            patient.patientType !== ProgrammeType.GLP && (
              <ChangeStartDate
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
            ),
        },
        [PAGE_TYPES.COINS]: {
          label: "Edit patient coins and streak",
          content: patient ? (
            <EditPatientCoins
              data={patient}
              refresh={() => {
                setRefreshCounter(refreshCounter + 1);
              }}
            />
          ) : null,
        },
        [PAGE_TYPES.CHANGE_PAYMENT_DATE]: {
          label: "Edit patient database paymentDate",
          content:
            isHabitualAdmin &&
            patient &&
            (
              <ChangePaymentDate
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
            ),
        },
        [PAGE_TYPES.CHANGE_SHIPPING_ADDRESS]: {
          label: "Edit patient default shipping address",
          content:
            isHabitualAdmin &&
            patient &&
            (
              <ChangePatientAddress
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
            ),
        },
        [PAGE_TYPES.SWITCH_PROGRAMME]: {
          label: "Switch programme content",
          content:
            isHabitualAdmin &&
            patient &&
            (
              <ChangeProgramme
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
            ),
        },
        [PAGE_TYPES.SWITCH_GLP_PROGRAMME]: {
          label: "Switch subscription product",
          content:
            isHabitualAdmin &&
            patient &&
             (
              <ChangeProduct
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
            ) ,
        },
        [PAGE_TYPES.RESET_TO_WELCOME_GUIDE]: {
          label: "Reset patient to Welcome Guide",
          content:
            patient && patient.patientType !== ProgrammeType.GLP ? (
              <>
               <ResetToWelcomeGuide
                data={patient}
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
              />
              <ChecklistTasks
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
                data={patient}
                loading={(loading: boolean) => {
                  setLoading(loading);
                }}
              />
              </>
             
            ) : null,
        },
        [PAGE_TYPES.SEND_MAGIC_LINK]: {
          label: "Re-send patient magic link",
          content: <MagicLinkSender data={patient} />,
        },
        [PAGE_TYPES.ID_VERIFICATION]: {
          label: "Manually edit checklist tasks",
          content:
            isHabitualAdmin &&
            patient &&
            patient.patientType === ProgrammeType.GLP ? (
              <ChecklistTasks
                refresh={() => {
                  setRefreshCounter(refreshCounter + 1);
                }}
                data={patient}
                loading={(loading: boolean) => {
                  setLoading(loading);
                }}
              />
            ) : null,
        },
        [PAGE_TYPES.MEDICAL_REVIEW]: {
          label: "View Patient Medication Review Responses",
          content:
            isHabitualAdmin &&
            patient &&
            patient.patientType === ProgrammeType.GLP ? (
              <MedicalReviewResponses data={patient} />
            ) : null,
        },
        [PAGE_TYPES.INITIAL_CONSULTATION]: {
          label: "View Patient Initial Consultation Responses",
          content:
            isHabitualAdmin &&
            patient &&
             (
              <InitialConsultationResponses
                data={patient}
                initialConsultation={true}
              />
            ),
        },
        [PAGE_TYPES.VIEW_PATIENT_NOTES]: {
          label: "See/add patient notes",
          content: (
            <PatientNotes
              email={email}
              data={patientNotes}
              isLoading={(input) => {
                setLoading(input);
              }}
            />
          ),
        },
        [PAGE_TYPES.FEATURE_FLAGS]: {
          label: "Edit feature flags",
          content:
            patient && !patient.trialPatient ? (
              <EditFeatureFlags
                email={email}
                data={
                  flags &&
                  flags.filter((f: FeatureFlag) => {
                    return (
                      isHabitualAdmin ||
                      [
                        FeatureFlags.BLOOD_GLUCOSE,
                        FeatureFlags.BLOOD_PRESSURE,
                        FeatureFlags.TEST_USER,
                      ].includes(f.feature as FeatureFlags)
                    );
                  })
                }
                isLoading={(input) => {
                  setLoading(input);
                }}
                setFlags={(data: FeatureFlag[]) => setFlags(data)}
                authToken={authToken}
              />
            ) : null,
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, patient, refreshCounter, flags]
  );

  const PatientsActivitySelector = ({ type }: { type: string | null }) => {
    return (
      <div className={"activities"}>
        <div className={"activityPanel cascade"}>
          <div className={"activityHeader"}>
            <h3>{"What would you like to do?"}</h3>
            <span>{"Please select from below"}</span>
          </div>
          {Object.keys(activityMapper).map((activityKey, index) => {
            const currentActivity = activityMapper[activityKey as PAGE_TYPES];
            return (
              <Fragment key={index}>
                {activityMapper[activityKey as PAGE_TYPES]?.content && (
                  <button
                    className={activity === activityKey ? "active" : "default"}
                    onClick={() => setActivity(activityKey as PAGE_TYPES)}
                  >
                    {currentActivity.label}
                  </button>
                )}
              </Fragment>
            );
          })}
        </div>
        <div className={"activityScreen"}>
          {activityMapper[type as PAGE_TYPES]?.content}
        </div>
      </div>
    );
  };

  const ErrorMessage = () => {
    return (
      <div>
        <span>{"Looks like there was a break in one of my circuits"}</span>
      </div>
    );
  };

  return (
    <div className={"managePatients"}>
      {!patient ? (
        <Fragment>
          <div className={"emailInput"}>
            <div className={"emailInputLabel cascade"}>
              <label>{"Please enter patient details:"}</label>
              <span>{"You can search by name, ID or email address"}</span>
            </div>
            <input
              onChange={(e) => setPatientQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handlePatientsSearch();
                }
              }}
            />
            <button className={"cta"} onClick={handlePatientsSearch}>
              {"Search"}
            </button>
          </div>
          {!!patientList && !patient && (
            <PatientList list={patientList} onClick={setEmail} />
          )}
          {!patient && error && <ErrorMessage />}
        </Fragment>
      ) : (
        <Fragment>
          <div className={"viewing"}>
            <div className={"viewingText"}>
              <label>{"You are currently managing:"}</label>
              <span>{patient["fullname"]}</span>
            </div>
            <button
              className={"cta"}
              onClick={() => {
                setPatient(null);
                setEmail(null);
                setActivity(PAGE_TYPES.VIEW_PATIENT_DATA);
              }}
            >
              {"Change patient"}
            </button>
            <button
              className={"cta"}
              style={{ marginLeft: 12 }}
              onClick={() => {
                handlePatientDetailsSearch();
              }}
            >
              {"🔄"}
            </button>
            <button
              className={"cta"}
              style={{ marginLeft: 12 }}
              onClick={() => {
                navigate(`/prescriptionPayments?query=${patient.email}`);
              }}
            >
              {"🔎"}
            </button>
          </div>
          {!!patient && <PatientsActivitySelector type={activity} />}
          {!patient && error && <ErrorMessage />}
        </Fragment>
      )}
    </div>
  );
};
